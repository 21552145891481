import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import "./assets/css/sb-admin-2.css";
import "./assets/css/global.css";
import "./assets/vendor/jquery/jquery.js";
import "./assets/vendor/bootstrap/js/bootstrap.bundle.js";
import "./assets/vendor/jquery-easing/jquery.easing.js";
import "./assets/js/sb-admin-2.js";
import "./assets/vendor/fontawesome-free/css/all.min.css";
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
