import { Button, Form, Modal } from "react-bootstrap";
import { TransactionProps } from "../../utils/customInterfaces";
import { useState } from "react";
import EditButton from "../EditButton";

interface UpdateTransactionModalProps {
  transaction: TransactionProps;
  onClickSave: (transaction: TransactionProps) => void;
}

const UpdateTransactionModal: React.FC<UpdateTransactionModalProps> = ({
  transaction,
  onClickSave,
}) => {
  const [transactionInfo, setTransactionInfo] = useState<TransactionProps>(transaction);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleSave = (transaction: TransactionProps) => {
    onClickSave(transaction);
    handleClose();
  };

  return(
    <>
    <EditButton onClick={handleShow} />
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="date">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              disabled
              value={new Date(transactionInfo.date).toISOString().split('T')[0]} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="debit">
            <Form.Label>Outward</Form.Label>
            <Form.Control
              type="number"
              value={transactionInfo.debit}
              onChange={(e) => setTransactionInfo({ ...transactionInfo, debit: parseInt(e.target.value) })} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="credit">
            <Form.Label>Inward</Form.Label>
            <Form.Control
              type="number"
              value={transactionInfo.credit}
              onChange={(e) => setTransactionInfo({ ...transactionInfo, credit: parseInt(e.target.value) })} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="remark">
            <Form.Label>Remark</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={transactionInfo.remark}
              onChange={(e) => setTransactionInfo({ ...transactionInfo, remark: e.target.value })} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
        <Button variant="primary" onClick={() => handleSave(transactionInfo)}>Save</Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default UpdateTransactionModal;
