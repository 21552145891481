import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Config } from "../../common";
import { GSTDetailsProps } from "../../utils/customInterfaces";

interface EditGSTDetailsProps {
  gstDetails: GSTDetailsProps;
  onClickUpdate: (gstDetailsInfo: GSTDetailsProps) => void;
}

const EditGSTDetailsForm = (props: EditGSTDetailsProps) => {
  const { gstDetails, onClickUpdate } = props;
  const [gstDetailsInfo, setGSTDetailsInfo] = useState(gstDetails);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [imageType, setImageType] = useState("");
  const [file, setFile] = useState(null as File | null);

  const backendUrl = Config.server.backendUrl;
  const baseUrl = Config.server.baseUrl;

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const uploadImageHandler = async () => {
    const auth: any = localStorage.getItem("accessToken");
    const accessToken = auth ? JSON.parse(auth) : "";
    const formData = new FormData();
    if (file) formData.append("file", file);
    await axios
      .post(`${baseUrl}/shared/upload-file`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        toast.success(response.data.result.message);
        setGSTDetailsInfo({
          ...gstDetailsInfo,
          [imageType]: response.data.result.data.fileName,
        });

        setShowUploadImageModal(false);
        setFile(null);
        setImageType("");
      });
  };

  const handleSubmit = () => {
    onClickUpdate(gstDetailsInfo);
  };

  useEffect(() => {
    setGSTDetailsInfo(gstDetails);
  }, [gstDetails]);

  return (
    <div>
      <Form>
        <Form.Group className="mb-3" controlId="dayBookId">
          <Form.Label>GST Details Id</Form.Label>
          <Form.Control
            type="text"
            disabled
            value={gstDetailsInfo.gstDetailsId}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Firm Name</Form.Label>
          <Form.Control
            type="text"
            required
            value={gstDetailsInfo.firmName}
            onChange={(e) =>
              setGSTDetailsInfo({ ...gstDetailsInfo, firmName: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Mobile Number</Form.Label>
          <Form.Control
            type="text"
            required
            value={gstDetailsInfo.mobile}
            onChange={(e) =>
              setGSTDetailsInfo({ ...gstDetailsInfo, mobile: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            required
            value={gstDetailsInfo.email}
            onChange={(e) =>
              setGSTDetailsInfo({ ...gstDetailsInfo, email: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Website Url</Form.Label>
          <Form.Control
            type="text"
            required
            value={gstDetailsInfo.websiteUrl}
            onChange={(e) =>
              setGSTDetailsInfo({
                ...gstDetailsInfo,
                websiteUrl: e.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>GST Number</Form.Label>
          <Form.Control
            type="text"
            required
            value={gstDetailsInfo.gstNumber}
            onChange={(e) =>
              setGSTDetailsInfo({
                ...gstDetailsInfo,
                gstNumber: e.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Pan Number</Form.Label>
          <Form.Control
            type="text"
            required
            value={gstDetailsInfo.panNumber}
            onChange={(e) =>
              setGSTDetailsInfo({
                ...gstDetailsInfo,
                panNumber: e.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="description">
          <Form.Label>Address</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={gstDetailsInfo.address}
            onChange={(e) =>
              setGSTDetailsInfo({ ...gstDetailsInfo, address: e.target.value })
            }
          />
        </Form.Group>
        <div className="d-flex align-items-center justify-content-around">
          {gstDetailsInfo.logo && (
            <div className="align-items-center">
              <img
                id="logoPreview"
                src={`${backendUrl}/uploads/${gstDetailsInfo.logo}`}
                alt="Logo preview"
                style={{
                  marginTop: "10px",
                  maxWidth: "100px",
                  maxHeight: "100px",
                }}
              />
              <div className="d-flex justify-content-around">
                <span>Logo</span>
              </div>
            </div>
          )}
          {gstDetailsInfo.signature && (
            <div>
              <img
                id="logoSignature"
                src={`${backendUrl}/uploads/${gstDetailsInfo.signature}`}
                alt="Signature preview"
                style={{
                  marginTop: "10px",
                  maxWidth: "100px",
                  maxHeight: "100px",
                }}
              />
              <div className="d-flex justify-content-around">
                <span>Signature</span>
              </div>
            </div>
          )}
          {gstDetailsInfo.stamp && (
            <div>
              <img
                id="logoPreview"
                src={`${backendUrl}/uploads/${gstDetailsInfo.stamp}`}
                alt="Stamp preview"
                style={{
                  marginTop: "10px",
                  maxWidth: "100px",
                  maxHeight: "100px",
                }}
              />
              <div className="d-flex justify-content-around">
                <span>Stamp</span>
              </div>
            </div>
          )}
        </div>
      </Form>
      <div className="d-flex justify-content-center">
        <div>
          <div className="p-2 d-flex justify-content-center">
            <Button className="btn btn-sm" onClick={() => setShowUploadImageModal(true)}>
              Update Images
            </Button>
          </div>
          <div className="p-2">
            <Button className="btn btn-md" style={{width: '200px'}} variant="primary" onClick={() => handleSubmit()}>
              Update
            </Button>
          </div>
        </div>
      </div>

      {showUploadImageModal && (
        <>
          <Modal
            show={showUploadImageModal}
            onHide={() => setShowUploadImageModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Upload Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="firmName">
                  <Form.Label>Image Type</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setImageType(e.target.value)
                    }
                    // custom
                  >
                    <option value="">Select...</option>
                    <option value="logo">Logo</option>
                    <option value="signature">Signature</option>
                    <option value="stamp">Stamp</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="firmName">
                  <Form.Control
                    name="file"
                    type="file"
                    placeholder="Choose Image"
                    required
                    onChange={handleImageChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowUploadImageModal(false)}
              >
                Close
              </Button>
              <Button variant="primary" onClick={uploadImageHandler}>
                Upload
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};

export default EditGSTDetailsForm;
