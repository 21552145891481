import { LoginForm } from "../../component";
import Api from "../../Api";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const api = Api();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <LoginForm
        onLogin={(username: string, password: string) => {
          api.user.login(username, password).then(() => {
            navigate(location.state?.from?.pathname || '/');
          });
        }} />
    </>
  );
};

export default Login;