import { Button, Form, Modal, Table } from "react-bootstrap";
import Pagination from "react-js-pagination";

import axios from "axios";
import { ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Config } from "../../common";
import { GSTDetailsProps } from "../../utils/customInterfaces";
import DeleteButton from "../DeleteButton";
import ViewItemsPerPage from "../ViewItemsPerPage";

interface GSTDetailsListingProps {
  gstDetailsList: GSTDetailsProps[];
  totalItemsCount: number;
  pageIndex: number;
  pageSize: number;
  onChangePageIndex: (pageNumber: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onClickAddGSTDetails: (GSTDetail: GSTDetailsProps) => void;
  onClickDeleteGSTDetail: (gstDetailsId: number) => void;
}

const GSTDetailsListing = (props: GSTDetailsListingProps) => {
  const {
    gstDetailsList,
    totalItemsCount,
    pageIndex,
    pageSize,
    onChangePageIndex,
    onChangePageSize,
    onClickAddGSTDetails,
    onClickDeleteGSTDetail,
  } = props;

  const [showGSTDetailsModal, setShowGSTDetailsModal] = useState(false);
  const initialGSTDetailsState = {
    gstDetailsId: 0,
    firmName: "",
    address: "",
    mobile: "",
    email: "",
    websiteUrl: "",
    gstNumber: "",
    panNumber: "",
    signature: "",
    logo: "",
    stamp: "",
  };
  const [GSTDetail, setGSTDetails] = useState(initialGSTDetailsState);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [imageType, setImageType] = useState("");
  const [file, setFile] = useState(null as File | null);

  const handleShowModal = () => setShowGSTDetailsModal(true);
  const handleCloseModal = () => setShowGSTDetailsModal(false);
  const resetGSTDetailsState = () => setGSTDetails(initialGSTDetailsState);
  const handleAddGSTDetails = (GSTDetail: GSTDetailsProps) => {
    onClickAddGSTDetails(GSTDetail);
    handleCloseModal();
    resetGSTDetailsState();
  };
  const baseUrl = Config.server.baseUrl;
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const uploadImageHandler = async () => {
    const auth: any = localStorage.getItem("accessToken");
    const accessToken = auth ? JSON.parse(auth) : "";
    const formData = new FormData();
    if (file) formData.append("file", file);
    await axios
      .post(`${baseUrl}/shared/upload-file`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        toast.success(response.data.result.message);
        setGSTDetails({
          ...GSTDetail,
          [imageType]: response.data.result.data.fileName,
        });

        setShowUploadImageModal(false);
        setFile(null);
        setImageType("");
      });
  };

  return (
    <div>
      <div className="col-md-9">
        <h2 className="h3 mb-4 text-gray-800">GST Details</h2>
      </div>
      <Button className="mb-3" variant="primary" onClick={handleShowModal}>
        Add New
      </Button>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Firm Name</th>
            <th>GST Number</th>
            <th>Pan Number</th>
            {/* <th></th> */}
          </tr>
        </thead>
        <tbody>
          {gstDetailsList.map((GSTDetail) => (
            <tr key={GSTDetail.gstDetailsId}>
              <td><Link to={`${GSTDetail.gstDetailsId}`}>{GSTDetail.gstDetailsId}</Link></td>
              <td><Link to={`${GSTDetail.gstDetailsId}`}>{GSTDetail.firmName}</Link></td>
              <td>{GSTDetail.gstNumber}</td>
              <td>{GSTDetail.panNumber}</td>
              <td>
                <DeleteButton
                  onClick={() => {
                    if (GSTDetail.gstDetailsId) {
                      onClickDeleteGSTDetail(GSTDetail.gstDetailsId);
                    }
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        activePage={pageIndex + 1}
        totalItemsCount={totalItemsCount}
        itemsCountPerPage={pageSize}
        onChange={onChangePageIndex}
        itemClass="page-item"
        linkClass="page-link"
        firstPageText="First"
        lastPageText="Last"
      />
      <ViewItemsPerPage
        selectedPageSize={pageSize}
        onSelectPageSize={onChangePageSize}
      />
      <Modal show={showGSTDetailsModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {GSTDetail.gstDetailsId
              ? "Update GST Details"
              : "Add New GST Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="firmName">
              <Form.Label>Firm Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Firm Name"
                required
                value={GSTDetail.firmName}
                onChange={(e) =>
                  setGSTDetails({ ...GSTDetail, firmName: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="mobile">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Mobile Number"
                required
                value={GSTDetail.mobile}
                onChange={(e) =>
                  setGSTDetails({ ...GSTDetail, mobile: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="firmName">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email"
                required
                value={GSTDetail.email}
                onChange={(e) =>
                  setGSTDetails({ ...GSTDetail, email: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="firmName">
              <Form.Label>Website Url</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter websiteUrl"
                required
                value={GSTDetail.websiteUrl}
                onChange={(e) =>
                  setGSTDetails({ ...GSTDetail, websiteUrl: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="firmName">
              <Form.Label>GST Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter gstNumber"
                required
                value={GSTDetail.gstNumber}
                onChange={(e) =>
                  setGSTDetails({ ...GSTDetail, gstNumber: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="firmName">
              <Form.Label>Pan Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter panNumber"
                required
                value={GSTDetail.panNumber}
                onChange={(e) =>
                  setGSTDetails({ ...GSTDetail, panNumber: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={GSTDetail.address}
                onChange={(e) =>
                  setGSTDetails({ ...GSTDetail, address: e.target.value })
                }
              />
            </Form.Group>
            <Button onClick={() => setShowUploadImageModal(true)}>
              Upload Images
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleAddGSTDetails(GSTDetail)}
          >
            {GSTDetail.gstDetailsId ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </Modal>

      {showUploadImageModal && (
        <>
          <Modal
            show={showUploadImageModal}
            onHide={() => setShowUploadImageModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Upload Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="firmName">
                  <Form.Label>Image Type</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setImageType(e.target.value)
                    }
                    // custom
                  >
                    <option value="">Select...</option>
                    <option value="logo">Logo</option>
                    <option value="signature">Signature</option>
                    <option value="stamp">Stamp</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="firmName">
                  <Form.Control
                    name="file"
                    type="file"
                    placeholder="Choose Image"
                    required
                    onChange={handleImageChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setShowUploadImageModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={uploadImageHandler}>
                Upload
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};

export default GSTDetailsListing;
