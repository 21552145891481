import { GSTDetailsProps } from "../utils/customInterfaces";

const GSTDetails = (api: any) => ({
  createGSTDetails: (gstDetails: GSTDetailsProps) =>
    api.post('/gstDetails/createGstDetails', {}, gstDetails),
  updateGSTDetails: (gstDetailsId: number, gstDetails: GSTDetailsProps) =>
    api.put(`/gstDetails/${gstDetailsId}`, {}, gstDetails),
  getGSTDetailsListing: (pageIndex?: number, pageSize?: number) =>
    api.get('/gstDetails', { pageIndex, pageSize }, {}),
  deleteGSTDetails: (gstDetailsId: number) =>
    api.remove(`/gstDetails/${gstDetailsId}`),
  getGSTDetails: (gstDetailsId: number) =>
    api.get(`/gstDetails/${gstDetailsId}`, {}, {}),
});

export default GSTDetails;