import React from "react";

const Home = () => {
  return <div>
    <div>Dashboard</div>
    <div className="d-flex align-item-center justify-content-center">Comming Soon</div>
  </div>;
};

export default Home;
