import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Config } from "../common";
import { setIsLoggedIn, updateUserInfo } from "../redux/User";
import DayBook from "./DayBook";
import DayBookTransaction from "./DayBookTransaction";
import GSTDetails from "./GSTDetails";
import User from "./User";
import BankDetails from "./BankDetails";

const Api = () => {
  const apiService = ApiService();
  return {
    user: User(apiService),
    dayBook: DayBook(apiService),
    transaction: DayBookTransaction(apiService),
    GSTDetails: GSTDetails(apiService),
    bankDetails: BankDetails(apiService),
  }
};

export default Api;

const ApiService = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const post = (path: string, params: any, data: any) => send(path, 'POST', params, data);
  const put = (path: string, params: any, data: any) => send(path, 'PUT', params, data);
  const get = (path: string, params: any, data: any) => send(path, 'GET', params, data);
  const remove = (path: string, params: any, data: any) => send(path, 'DELETE', params, data);

  const send = (path: string, method: any, params: any, data: any) => {
    const baseUrl = Config.server.baseUrl;
    const url = `${baseUrl}${path}`;

    return new Promise((resolve, reject) => {
      const initialHeaders = {
        'Content-Type':
        data instanceof FormData ? 'multipart/form-data' : 'application/json',
        Accept: 'application/json',
      };
      const auth: any = localStorage.getItem('accessToken');
      const accessToken = auth ? JSON.parse(auth) : '';
      const additionalHeaders = accessToken
        ? { Authorization: `Bearer ${accessToken}`}
        : {};
      const headers = {...initialHeaders, ...additionalHeaders}

      const axiosArgs = Object.entries({url, method, headers, params, data})
        .filter((entry) => {
          const value = entry[1];
          const isEmptyObject =
            value != null &&
            typeof value === 'object' &&
            Object.keys(value).length === 0;
          return !isEmptyObject;
        })
        .reduce((acc, entry) => {
          const [key, value] = entry;
          return {...acc, [key]: value};
        }, {});

      axios(axiosArgs)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              logout();
            } else {
              console.log('error:::',error.response.data.error.errors[0]);
              toast.error(error.response.data.error.errors[0]);
              if (error.response.data.error.error_params) {
                error.response.data.error.error_params.forEach((e: any) => {
                  toast.error(e.msg);
                });
              }
            }
          } else if (error.request) {
            reject(createError('No Response from server', error.request));
          } else {
            reject(createError('Error processing request', error.message));
          }
        });
    });
  };

  const login = (email: string, password: string) => {
    const baseUrl = Config.server.baseUrl;

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/user/login`, {email, password}, {headers})
        .then((response) => {
          const loginResponse = response.data.result.data;
          const accessToken = loginResponse.token;
          localStorage.setItem("accessToken", JSON.stringify(accessToken));
          dispatch(updateUserInfo(loginResponse));
          dispatch(setIsLoggedIn(true));
          resolve(loginResponse);
        })
        .catch((error) => {
          if (error.response) {
            console.log('error:::',error.response.data.error.errors[0]);
            toast.error(error.response.data.error.errors[0]);
            if (error.response.data.error.error_params) {
              error.response.data.error.error_params.forEach((e: any) => {
                toast.error(e.msg);
              });
            }
          } else if (error.request) {
            reject(error.request);
          } else {
            reject(error.message);
          }
        });
    });
  };

  const logout = () => {
    dispatch(updateUserInfo({}));
    dispatch(setIsLoggedIn(false));
    navigate("/login");
  };

  return {
    post,
    get,
    put,
    remove,
    login,
    logout,
  };
};

const createError = (name: string, message: string) => {
  const error = new Error();
  error.name = name;
  error.message = message;
  return error;
};
