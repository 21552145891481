import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Api from "../../Api";
import GSTDetailsListing from "../../component/GSTDetailsListing";
import { GSTDetailsProps } from "../../utils/customInterfaces";

const FindGSTDetails = () => {
  const api = Api();

  const [GSTDetails, setGSTDetails] = useState([]);
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(1);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const onChangePageIndex = (pageNumber: number) =>
    setCurrentPageIndex(pageNumber - 1);
  const onChangePageSize = (pageSize: number) => setCurrentPageSize(pageSize);
  const onClickAddGSTDetails = async (gstDetails: GSTDetailsProps) => {
    api.GSTDetails.createGSTDetails(gstDetails).then((response: any) => {
      toast.success(response.result.message);
      getGSTDetails(currentPageIndex, currentPageSize);
    });
  };

  const getGSTDetails = (pageIndex: number, pageSize: number) => {
    api.GSTDetails.getGSTDetailsListing(pageIndex, pageSize).then(
      (response: any) => {
        const GSTDetailsResponse = response.result.data;
        setGSTDetails(GSTDetailsResponse.gstDetails);
        setTotalNumberOfItems(GSTDetailsResponse.gstDetailsListCount);
      }
    );
  };

  const handleDeleteGSTDetails = (gstDetailsId: number) => {
    api.GSTDetails.deleteGSTDetails(gstDetailsId).then((response: any) => {
      toast.success(response.result.message);
      getGSTDetails(currentPageIndex, currentPageSize);
    });
  };

  useEffect(() => {
    getGSTDetails(currentPageIndex, currentPageSize);
  }, [currentPageIndex, currentPageSize]);

  return (
    <GSTDetailsListing
      gstDetailsList={GSTDetails}
      totalItemsCount={totalNumberOfItems}
      pageIndex={currentPageIndex}
      pageSize={currentPageSize}
      onChangePageIndex={onChangePageIndex}
      onChangePageSize={onChangePageSize}
      onClickAddGSTDetails={(gstDetails) => onClickAddGSTDetails(gstDetails)}
      onClickDeleteGSTDetail={(gstDetailsId) =>
        handleDeleteGSTDetails(gstDetailsId)
      }
    />
  );
};

export default FindGSTDetails;
