import { BankDetailsProps } from "../utils/customInterfaces";

const BankDetails = (api: any) => ({
  createBankDetails: (bankDetails: BankDetailsProps) =>
    api.post('/bankDetails/createBankDetails', {}, bankDetails),
  updateBankDetails: (bankDetailsId: number, bankDetails: BankDetailsProps) =>
    api.put(`/bankDetails/${bankDetailsId}`, {}, bankDetails),
  getBankDetailsListing: (pageIndex?: number, pageSize?: number) =>
    api.get('/bankDetails', { pageIndex, pageSize }, {}),
  deleteBankDetails: (bankDetailsId: number) =>
    api.remove(`/bankDetails/${bankDetailsId}`),
});

export default BankDetails;