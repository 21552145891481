import { DayBookProps } from "../utils/customInterfaces";

const User = (api: any) => ({
  login: (email: string, password: string) =>
    api.login(email, password),
  logout: () => api.logout(),
  createUserDayBook: (dayBook: DayBookProps) =>
    api.post('/user/day-book', {}, dayBook),
  getUserDayBooks: (pageIndex?: number, pageSize?: number) =>
    api.get('/user/day-book', { pageIndex, pageSize }, {}),
  changePassword:  (password: string) =>
    api.post('/user/change-password', {}, {password: password}),
});

export default User;