import { persistCombineReducers } from "redux-persist";
import storage from 'redux-persist/lib/storage';

import User from "./User";

const config = {
  key: 'root',
  storage,
};

export default persistCombineReducers(config, {
  user: User,
});