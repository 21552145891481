import { Route, Routes } from "react-router-dom";

import { UserRoles } from "../common/constants/UserRoles";
import {
  DayBookDetails,
  FindDayBook,
  FindGSTDetails,
  HomeContainer,
  Login
} from "../screen";
import Protected from "./Protected";
import FindBankDetails from "../screen/FindBankDetails";
import GSTDetails from "../screen/GSTDetails";

const Router = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <Protected role={UserRoles.ADMIN}>
            <HomeContainer />
          </Protected>
        }
      />
      <Route
        path="/gst"
        element={
          <Protected role={UserRoles.ADMIN}>
            <FindGSTDetails />
          </Protected>
        }
      />
           <Route
        path="/gst/:gstDetailsId"
        element={
          <Protected role={UserRoles.ADMIN}>
            <GSTDetails />
          </Protected>
        }
      />
      <Route
        path="/bank"
        element={
          <Protected role={UserRoles.ADMIN}>
            <FindBankDetails />
          </Protected>
        }
      />
      <Route
        path="/day-book"
        element={
          <Protected role={UserRoles.ADMIN}>
            <FindDayBook />
          </Protected>
        }
      />
      <Route
        path="/day-book/:dayBookId"
        element={
          <Protected role={UserRoles.ADMIN}>
            <DayBookDetails />
          </Protected>
        }
      />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};

export default Router;
