import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";

import { EditDayBookForm, TransactionListing } from "../../component";
import { DayBookProps, FindTransactionsProps, TransactionProps } from "../../utils/customInterfaces";
import Api from "../../Api";

interface DayBookFilters {
  fromDate?: string;
  thruDate?: string;
  pageIndex?: number;
  pageSize?: number;
}

const DayBookDetails = () => {
  const { dayBookId } = useParams();
  const api = Api();

  const [dayBookInfo, setDayBookInfo] = useState({
    name: '',
    description: '',
  });
  const [transactionsList, setTransactionsList] = useState([]);
  const [totalnumberOfItems, setTotalNumberOfItems] = useState(1);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [findOptions, setFindOptions] = useState<FindTransactionsProps>({
    fromDate: '',
    thruDate: '',
  });

  const handleUpdate = (dayBookInfo: DayBookProps) => {
    if (dayBookInfo.dayBookId) {
      api.dayBook.updateDayBook(dayBookInfo.dayBookId, dayBookInfo)
        .then((response: any) => {toast.success(response.result.message)});
    }
  };
  const onChangePageIndex = (pageNumber: number) => setCurrentPageIndex((pageNumber - 1));
  const onChangePageSize = (pageSize: number) => setCurrentPageSize(pageSize);
  const getDayBookInfo = (dayBookId: number) => api.dayBook.getDayBook(dayBookId);
  const getTransactions = (dayBookId: number, dayBookFilters: DayBookFilters) =>
    api.dayBook.getDayBookTransactions({dayBookId, ...dayBookFilters});
  const fetchNewTransactions = (dayBookId: number, dayBookFilters: DayBookFilters) => {
    getTransactions(
      dayBookId,
      dayBookFilters
    ).then((response: any) => {
      const transactionResponse = response.result.data;
      setTransactionsList(transactionResponse.transactions);
      setTotalNumberOfItems(transactionResponse.transactionsListCount);
    });
  };
  const handleAddNewTransaction = (dayBookId: number, transaction: TransactionProps) => {
    api.dayBook.addDayBookTransaction(dayBookId, transaction)
      .then(((response: any) => {
        toast.success(response.result.message);
        fetchNewTransactions(
          dayBookId,
          {...findOptions, pageIndex: currentPageIndex, pageSize: currentPageSize}
        );
      }));
  };
  const handleUpdateTransaction = (dayBookId: number, transaction: TransactionProps) => {
    api.transaction.updateDayBookTransaction(transaction)
      .then((response: any) => {
        toast.success(response.result.message);
        fetchNewTransactions(
          dayBookId,
          {...findOptions, pageIndex: currentPageIndex, pageSize: currentPageSize}
        );
      });
  };
  const handleDeleteTransaction = (dayBookId: number, transactionId: number) => {
    api.transaction.deleteDayBookTransaction(transactionId)
      .then((response: any) => {
        toast.success(response.result.message);
        fetchNewTransactions(
          dayBookId,
          {...findOptions, pageIndex: currentPageIndex, pageSize: currentPageSize}
        );
      });
  };
  const handleFindTransactions = (dayBookId: number, findOptions: FindTransactionsProps) => {
    setFindOptions(findOptions);
    fetchNewTransactions(
      dayBookId,
      {...findOptions, pageIndex: currentPageIndex, pageSize: currentPageSize}
    );
  };
  const generateCSV = (dayBookId: number, fromDate?: string, thruDate?: string) => {
    api.dayBook.generateDayBookTransactionsCSV(dayBookId, fromDate, thruDate)
      .then((response: any) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'transactions.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
  };

  useEffect(() => {
    if (dayBookId) {
      getDayBookInfo(parseInt(dayBookId)).then((response: any) => {
        setDayBookInfo(response.result.data);
      });
    }
  }, [dayBookId]);

  useEffect(() => {
    if (dayBookId) {
      const parsedDayBookId = parseInt(dayBookId);
      fetchNewTransactions(
        parsedDayBookId,
        {...findOptions, pageIndex: currentPageIndex, pageSize: currentPageSize}
      );
    }
  }, [currentPageIndex, currentPageSize, dayBookId]);

  return (
    <Tabs
      defaultActiveKey="transactions"
      id="day-book-details"
      className="mb-3"
    >
      <Tab eventKey="editDayBook" title="Edit Day Book">
        <EditDayBookForm dayBook={dayBookInfo} onClickUpdate={handleUpdate} />
      </Tab>
      <Tab eventKey="transactions" title="Transactions">
        { dayBookId && <TransactionListing
          transactionsList={transactionsList}
          totalItemsCount={totalnumberOfItems}
          pageIndex={currentPageIndex}
          pageSize={currentPageSize}
          onChangePageIndex={onChangePageIndex}
          onChangePageSize={onChangePageSize}
          onClickNewTransaction={(transaction: TransactionProps) =>
            handleAddNewTransaction(parseInt(dayBookId), transaction)
          }
          onClickUpdateTransaction={(transaction: TransactionProps) =>
            handleUpdateTransaction(parseInt(dayBookId), transaction)
          }
          onClickDeleteTransaction={(transactionId: number) =>
            handleDeleteTransaction(parseInt(dayBookId), transactionId)
          }
          handleFindOptions={(findOptions: FindTransactionsProps) =>
            handleFindTransactions(parseInt(dayBookId), findOptions)
          }
          generateCSV={() => generateCSV(parseInt(dayBookId), findOptions.fromDate, findOptions.thruDate)} />
        }
      </Tab>
    </Tabs>
  );
};

export default DayBookDetails;
