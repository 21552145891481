import { Form, Button } from "react-bootstrap";
import { DayBookProps } from "../../utils/customInterfaces";
import { useEffect, useState } from "react";

interface EditDayBookProps {
  dayBook: DayBookProps;
  onClickUpdate: (dayBookInfo: DayBookProps) => void;
}

const EditDayBookForm = (props: EditDayBookProps) => {
  const { dayBook, onClickUpdate } = props;

  const [dayBookInfo, setDayBookInfo] = useState(dayBook);

  const handleSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    onClickUpdate: (dayBookInfo: DayBookProps
  ) => void, dayBookInfo: DayBookProps) => {
    event.preventDefault();
    onClickUpdate(dayBookInfo);
  };

  useEffect(() => {
    setDayBookInfo(dayBook);
  }, [dayBook]);

  return (
    <Form onSubmit={(event) => handleSubmit(event, onClickUpdate, dayBookInfo)}>
      <Form.Group className="mb-3" controlId="dayBookId">
        <Form.Label>Day Book Id</Form.Label>
        <Form.Control
          type="text"
          disabled
          value={dayBookInfo.dayBookId} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          required
          value={dayBookInfo.name}
          onChange={(e) => setDayBookInfo({...dayBookInfo, name: e.target.value})} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="description">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={dayBookInfo.description}
          onChange={(e) => setDayBookInfo({...dayBookInfo, description: e.target.value})} />
      </Form.Group>
      <Button variant="primary" type="submit">
        Update
      </Button>
    </Form>
  );
};

export default EditDayBookForm;
