// actions
const SET_USER_LOGIN_STATE = 'user/SET_USER_LOGIN_STATE';
const UPDATE_USER_INFO_STATE = 'user/UPDATE_USER_INFO_STATE';

// initial state
interface UserInitialState {
  errorMessage: string;
  isLoggedIn: boolean;
  userInfo: object;
}
const initialState: UserInitialState = {
  errorMessage: 'initial message',
  isLoggedIn: false,
  userInfo: {},
};

// reducers
const userReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_USER_LOGIN_STATE:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };
    case UPDATE_USER_INFO_STATE:
      return {
        ...state,
        userInfo: action.userInfo
      };
    default:
      return state;
  }
};
export default userReducer;

// action creators
export const setIsLoggedIn = (isLoggedIn: boolean) => {
  return {type: SET_USER_LOGIN_STATE, isLoggedIn};
};
export const updateUserInfo = (userInfo: UserInitialState['userInfo']) => ({
  type: UPDATE_USER_INFO_STATE,
  userInfo,
});

// selectors
export const selectLoginState = (state: any) => {
  return state.user.isLoggedIn;
}
export const selectUserInfo = (state: any) => {
  return state.user.userInfo;
};