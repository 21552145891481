import { useEffect, useState } from "react";

import { DayBookListing } from "../../component";
import { DayBookProps } from "../../utils/customInterfaces";
import { toast } from "react-toastify";
import Api from "../../Api";

const FindDayBook = () => {
  const api = Api();

  const [dayBooks, setDayBooks] = useState([]);
  const [totalnumberOfItems, setTotalNumberOfItems] = useState(1);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const onChangePageIndex = (pageNumber: number) => setCurrentPageIndex((pageNumber - 1));;
  const onChangePageSize = (pageSize: number) => setCurrentPageSize(pageSize);
  const onClickAddDayBook = (dayBook: DayBookProps) => {
    api.user.createUserDayBook(dayBook).then((response: any) => {
      toast.success(response.result.message);
      getDayBooks(currentPageIndex, currentPageSize);
    });
  };

  const getDayBooks = (pageIndex: number, pageSize: number) => {
    api.user.getUserDayBooks(pageIndex, pageSize).then((response: any) => {
      const dayBooksResponse = response.result.data;
      setDayBooks(dayBooksResponse.dayBooks);
      setTotalNumberOfItems(dayBooksResponse.dayBooksListCount);
    });
  };

  useEffect(() => {
    console.log("useEffect triggered with currentPageIndex:", currentPageIndex);
    getDayBooks(currentPageIndex, currentPageSize);
  }, [currentPageIndex, currentPageSize]);

  return (
    <DayBookListing
      dayBooksList={dayBooks}
      totalItemsCount={totalnumberOfItems}
      pageIndex={currentPageIndex}
      pageSize={currentPageSize}
      onChangePageIndex={onChangePageIndex}
      onChangePageSize={onChangePageSize}
      onClickAddDayBook={onClickAddDayBook} />
  );
};

export default FindDayBook;
