import { Home } from "../../component";

const HomeContainer = () => {
  return (
    <>
    <Home />
    </>
  );
};

export default HomeContainer;