import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Api from "../../Api";
import BankDetailsListing from "../../component/BankDetailsListing";
import { BankDetailsProps } from "../../utils/customInterfaces";

const FindBankDetails = () => {
  const api = Api();

  const [bankDetail, setBankDetails] = useState([]);
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(1);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const onChangePageIndex = (pageNumber: number) => setCurrentPageIndex((pageNumber - 1));
  const onChangePageSize = (pageSize: number) => setCurrentPageSize(pageSize);
  const onClickAddBankDetails = (bankDetail: BankDetailsProps) => {
    if(!!bankDetail.bankDetailsId){
      api.bankDetails.updateBankDetails(bankDetail.bankDetailsId,bankDetail).then((response: any) => {
        toast.success(response.result.message);
        getBankDetails(currentPageIndex, currentPageSize);
      });
    }else{
      api.bankDetails.createBankDetails(bankDetail).then((response: any) => {
        toast.success(response.result.message);
        getBankDetails(currentPageIndex, currentPageSize);
      });
    }
    
  };

  const getBankDetails = (pageIndex: number, pageSize: number) => {
    api.bankDetails.getBankDetailsListing(pageIndex, pageSize).then((response: any) => {
      const BankDetailsResponse = response.result.data;
      setBankDetails(BankDetailsResponse.bankDetails);
      setTotalNumberOfItems(BankDetailsResponse.bankDetailsListCount);
    });
  };

  const handleDeleteBankDetails = (bankDetailsId: number) => {
    api.bankDetails.deleteBankDetails(bankDetailsId)
      .then((response: any) => {
        toast.success(response.result.message);
        getBankDetails(currentPageIndex, currentPageSize);
      });
  };

  useEffect(() => {
    getBankDetails(currentPageIndex, currentPageSize);
  }, [currentPageIndex, currentPageSize]);

  return (
    <BankDetailsListing
    bankDetailsList={bankDetail}
      totalItemsCount={totalNumberOfItems}
      pageIndex={currentPageIndex}
      pageSize={currentPageSize}
      onChangePageIndex={onChangePageIndex}
      onChangePageSize={onChangePageSize}
      onClickAddBankDetails={(bankDetail)=>onClickAddBankDetails(bankDetail)} 
      onClickDeleteBankDetail={(bankDetailsId)=>handleDeleteBankDetails(bankDetailsId)}/>
  );
};

export default FindBankDetails;
