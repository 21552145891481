import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../Api";
import EditGSTDetailsForm from "../../component/EditGSTDetails";
import { GSTDetailsProps } from "../../utils/customInterfaces";

const GSTDetails = () => {
    const { gstDetailsId } = useParams();
  const api = Api();

  const [GSTDetails, setGSTDetails] = useState({});


  const getGSTDetails = () => {
    api.GSTDetails.getGSTDetails(Number(gstDetailsId)).then((response: any) => {
      const GSTDetailsResponse = response.result.data;
      setGSTDetails(GSTDetailsResponse.gstDetails);
    });
  };


  const updateGSTDetails = async (gstDetails: GSTDetailsProps) => {
    if(gstDetails.gstDetailsId){
      api.GSTDetails.updateGSTDetails(gstDetails.gstDetailsId, gstDetails).then((response: any) => {
        toast.success(response.result.message);
        getGSTDetails()
      });
    }
    
  };


  useEffect(() => {
    getGSTDetails();
  }, [gstDetailsId]);

  return (
    <EditGSTDetailsForm
    gstDetails={GSTDetails}
    onClickUpdate={(payload)=>updateGSTDetails(payload)}
    />
  );
};

export default GSTDetails;
