import React from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

interface ViewItemsPerPageProps {
  selectedPageSize: number;
  onSelectPageSize: (pageSize: number) => void;
}

const ViewItemsPerPage = (props: ViewItemsPerPageProps) => {
  const { selectedPageSize, onSelectPageSize } = props;

  const pageSizeOptions: number[] = [10, 20, 30];

  return (
    <DropdownButton
      title={`View Items Per Page: ${selectedPageSize}`}
      onSelect={(eventKey: any) => onSelectPageSize(Number(eventKey))}>
      {pageSizeOptions.map((pageSize, index) => (
        <Dropdown.Item key={index} eventKey={pageSize}>
          {pageSize}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default ViewItemsPerPage;
