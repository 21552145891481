import { useSelector } from "react-redux";

import { selectLoginState, selectUserInfo } from "../redux/User";
import NotFound from "../component/404";
import { Login } from "../screen";
import Layout from "../layout";

const Protected = (props: any) => {
  const { role, children } = props;

  const { isLoggedIn, userInfo } = useSelector((store: any) => ({
    isLoggedIn: selectLoginState(store),
    userInfo: selectUserInfo(store),
  }));

  return (
    <>
      {
        isLoggedIn
        ? (userInfo?.roles?.includes(role) ? <Layout>{children}</Layout> : <Layout><NotFound /></Layout>)
        : <Login />
      }
    </>
  );
};

export default Protected;
