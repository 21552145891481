import { Button, Form, Modal, Table } from "react-bootstrap";
import Pagination from "react-js-pagination";

import { useState } from "react";
import { BankDetailsProps } from "../../utils/customInterfaces";
import DeleteButton from "../DeleteButton";
import EditButton from "../EditButton";
import ViewItemsPerPage from "../ViewItemsPerPage";

interface BankDetailsListingProps {
  bankDetailsList: BankDetailsProps[];
  totalItemsCount: number;
  pageIndex: number;
  pageSize: number;
  onChangePageIndex: (pageNumber: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onClickAddBankDetails: (bankDetail: BankDetailsProps) => void;
  onClickDeleteBankDetail: (bankDetailsId: number) => void;
}

const BankDetailsListing = (props: BankDetailsListingProps) => {
  const {
    bankDetailsList,
    totalItemsCount,
    pageIndex,
    pageSize,
    onChangePageIndex,
    onChangePageSize,
    onClickAddBankDetails,
    onClickDeleteBankDetail,
  } = props;

  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
  const initialBankDetailsState = {
    bankDetailsId: 0,
    accountName: "",
    accountNumber: "",
    branch: "",
    ifscCode: "",
  };
  const [bankDetail, setBankDetails] = useState(initialBankDetailsState);

  const handleShowModal = () => setShowBankDetailsModal(true);
  const handleCloseModal = () => {setShowBankDetailsModal(false); resetBankDetailsState();};
  const resetBankDetailsState = () => setBankDetails(initialBankDetailsState);
  const handleAddBankDetails = (bankDetail: BankDetailsProps) => {
    onClickAddBankDetails(bankDetail);
    handleCloseModal();
    
  };

  const handleEditBankDetails = (bankDetail: BankDetailsProps) => {
    setBankDetails({
      bankDetailsId: bankDetail?.bankDetailsId || 0,
      accountName: bankDetail.accountName || "",
      accountNumber: bankDetail.accountNumber || "",
      branch: bankDetail.branch || "",
      ifscCode: bankDetail.ifscCode || "",
    });
    handleShowModal();
  };

  return (
    <div>
      <div className="col-md-9">
        <h2 className="h3 mb-4 text-gray-800">Bank Details</h2>
      </div>
      <Button className="mb-3" variant="primary" onClick={handleShowModal}>
        Add New
      </Button>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Account Name</th>
            <th>Account Number</th>
            <th>Branch</th>
            <th>IFSC Code</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {bankDetailsList.map((bankDetail) => (
            <tr key={bankDetail.bankDetailsId}>
              <td>{bankDetail.bankDetailsId}</td>
              <td>{bankDetail.accountName}</td>
              <td>{bankDetail.accountNumber}</td>
              <td>{bankDetail.branch}</td>
              <td>{bankDetail.ifscCode}</td>
              <td>
                <EditButton onClick={() => handleEditBankDetails(bankDetail)} />
              </td>
              <td>
                <DeleteButton
                  onClick={() => {
                    if (bankDetail.bankDetailsId) {
                      onClickDeleteBankDetail(bankDetail.bankDetailsId);
                    }
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        activePage={pageIndex + 1}
        totalItemsCount={totalItemsCount}
        itemsCountPerPage={pageSize}
        onChange={onChangePageIndex}
        itemClass="page-item"
        linkClass="page-link"
        firstPageText="First"
        lastPageText="Last"
      />
      <ViewItemsPerPage
        selectedPageSize={pageSize}
        onSelectPageSize={onChangePageSize}
      />
      <Modal show={showBankDetailsModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{bankDetail.bankDetailsId ? 'Update Bank Details' : 'Add New Bank Details'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="firmName">
              <Form.Label>Account Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Account Name"
                required
                value={bankDetail.accountName}
                onChange={(e) =>
                  setBankDetails({ ...bankDetail, accountName: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="mobile">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Account Number"
                required
                value={bankDetail.accountNumber}
                onChange={(e) =>
                  setBankDetails({ ...bankDetail, accountNumber:(e.target.value) })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="firmName">
              <Form.Label>Branch</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter branch"
                required
                value={bankDetail.branch}
                onChange={(e) =>
                  setBankDetails({ ...bankDetail, branch: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="firmName">
              <Form.Label>IFSC Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter IFSC code"
                required
                value={bankDetail.ifscCode}
                onChange={(e) =>
                  setBankDetails({ ...bankDetail, ifscCode: e.target.value })
                }
              />
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleAddBankDetails(bankDetail)}
          >
           {bankDetail.bankDetailsId? 'Update':  'Add'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BankDetailsListing;
