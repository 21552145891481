import {ReactNode} from "react";

import Footer from "./footer";
import Header from "./header";
import AdminSidebar from "./sidebar/AdminSidebar";

const Layout = ({ children }: { children: ReactNode }) => {

  return(
    <div id="page-top">
      <div id="wrapper">
        <AdminSidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />

            <div className="container-fluid">
              {children}
            </div>
          </div>

          <Footer />
        </div>
      </div>

      <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
      </a>

    </div>
  );
};

export default Layout;