import React, { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import Pagination from 'react-js-pagination';

import ViewItemsPerPage from "../ViewItemsPerPage";
import { FindTransactionsProps, TransactionProps } from "../../utils/customInterfaces";
import { formatDate } from "../../utils/helperFunctions";
import DeleteButton from "../DeleteButton";
import UpdateTransactionModal from "../UpdateTransactionModal";

interface TransactionListingProps {
  transactionsList: TransactionProps[];
  totalItemsCount: number;
  pageIndex: number;
  pageSize: number;
  onChangePageIndex: (pageNumber: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onClickNewTransaction: (transaction: TransactionProps) => void;
  onClickUpdateTransaction: (trasaction: TransactionProps) => void;
  onClickDeleteTransaction: (transactionId: number) => void;
  handleFindOptions: (findOptions: FindTransactionsProps) => void;
  generateCSV: () => void;
}

const TransactionListing = (props: TransactionListingProps) => {
  const {
    transactionsList,
    totalItemsCount,
    pageIndex,
    pageSize,
    onChangePageIndex,
    onChangePageSize,
    onClickNewTransaction,
    onClickUpdateTransaction,
    onClickDeleteTransaction,
    handleFindOptions,
    generateCSV,
  } = props;

  const [showFilter, setShowFilter] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const intitialTransactionState = {
    date: new Date().toISOString().split('T')[0],
    debit: 0,
    credit: 0,
    remark: ''
  };
  const [transaction, setTransaction] = useState<TransactionProps>(intitialTransactionState);
  const [findOptions, sendFindOptions] = useState({
    fromDate: '',
    thruDate: '',
  });

  const resetTransactionState = () => setTransaction(intitialTransactionState);
  const handleFilterClose = () => setShowFilter(false);
  const handleFilterShow = () => setShowFilter(true);
  const handleTransactionClose = () => setShowTransactionModal(false);
  const handleTransactionShow = () => setShowTransactionModal(true);
  const handleAddNewTransaction = (transaction: TransactionProps) => {
    onClickNewTransaction(transaction);
    handleTransactionClose();
    resetTransactionState();
  };
  const handleFindTransactions = (findOptions: FindTransactionsProps) => {
    handleFindOptions(findOptions);
    handleFilterClose();
  };

  return (
    <div>
      <Button className="mb-3" variant="primary" onClick={handleTransactionShow}>
        Add Transaction
      </Button>
      <Button className="ml-1 mb-3" variant="secondary" onClick={handleFilterShow}>
        Find Options
      </Button>
      <Button className="ml-1 mb-3" variant="primary" onClick={generateCSV}>
        CSV
      </Button>
      <Button className="ml-1 mb-3" variant="primary">
        PDF
      </Button>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>#Transaction</th>
            <th>Date</th>
            <th>Remark</th>
            <th>Outward</th>
            <th>Inward</th>
            <th>Balance</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {transactionsList.map((transaction) => (
            <tr key={transaction.transactionId}>
              <td>{transaction.transactionId}</td>
              <td>{formatDate(transaction.date)}</td>
              <td>{transaction.remark}</td>
              <td>{transaction.debit}</td>
              <td>{transaction.credit}</td>
              <td>{transaction.balance}</td>
              <td>
                <UpdateTransactionModal
                  transaction={transaction}
                  onClickSave={onClickUpdateTransaction} />
              </td>
              <td>
                <DeleteButton
                  onClick={() => {
                    if (transaction.transactionId) {
                      onClickDeleteTransaction(transaction.transactionId)
                    }
                  }} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination 
        activePage = {pageIndex + 1}
        totalItemsCount = {totalItemsCount}
        itemsCountPerPage = {pageSize}
        onChange = {onChangePageIndex}
        itemClass = "page-item"
        linkClass='page-link'
        firstPageText="First"
        lastPageText="Last"
      />
      <ViewItemsPerPage
        selectedPageSize={pageSize}
        onSelectPageSize={onChangePageSize} />
      <Modal show={showFilter} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apply Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="fromDate">
              <Form.Label>From Date</Form.Label>
              <Form.Control
                type="date"
                value={findOptions.fromDate}
                onChange={(e) => sendFindOptions({...findOptions, fromDate: e.target.value})} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="thruDate">
              <Form.Label>Thru Date</Form.Label>
              <Form.Control
                type="date"
                value={findOptions.thruDate}
                onChange={(e) => sendFindOptions({...findOptions, thruDate: e.target.value})} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleFilterClose}>Close</Button>
          <Button variant="primary" onClick={() => handleFindTransactions(findOptions)}>Apply</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showTransactionModal} onHide={handleTransactionClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="date">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                value={transaction.date}
                onChange={(e) => setTransaction({...transaction, date: e.target.value})} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="debit">
              <Form.Label>Outward</Form.Label>
              <Form.Control
                type="number"
                value={transaction.debit}
                onChange={(e) => setTransaction({...transaction, debit: parseFloat(e.target.value)})} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="credit">
              <Form.Label>Inward</Form.Label>
              <Form.Control
                type="number"
                value={transaction.credit}
                onChange={(e) => setTransaction({...transaction, credit: parseFloat(e.target.value)})} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="remark">
              <Form.Label>Remark</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={transaction.remark}
                onChange={(e) => setTransaction({...transaction, remark: e.target.value})} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleTransactionClose}>Close</Button>
          <Button variant="primary" onClick={() => handleAddNewTransaction(transaction)}>Add</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TransactionListing;
