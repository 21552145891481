import { DayBookProps, TransactionProps } from "../utils/customInterfaces";

const DayBook = (api: any) => ({
  updateDayBook: (dayBookId: number, data: DayBookProps) =>
    api.put(`/day-book/${dayBookId}`, {}, data),
  getDayBook: (dayBookId: number) =>
    api.get(`/day-book/${dayBookId}`, {}, {}),
  getDayBookTransactions: ({
    dayBookId,
    fromDate,
    thruDate,
    pageIndex,
    pageSize,
  }: {
    dayBookId: number;
    fromDate?: string;
    thruDate?: string;
    pageIndex?: number;
    pageSize?: number;
  }) => {
    const data = {
      fromDate: fromDate || '',
      thruDate: thruDate || '',
      pageIndex,
      pageSize,
    }
    return api.get(`/day-book/${dayBookId}/transaction`, data, {})
  },
  addDayBookTransaction: (dayBookId: number, transactionData: TransactionProps) =>
    api.post(`/day-book/${dayBookId}/transaction`, {}, transactionData),
  generateDayBookTransactionsCSV: (dayBookId: number, fromDate?: string, thruDate?: string) =>
    api.get(`/day-book/${dayBookId}/transaction/csv`, {fromDate, thruDate}, {}),
});

export default DayBook;