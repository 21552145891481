import { useNavigate } from "react-router-dom";

const AdminSidebar = () => {
  const navigate = useNavigate();

  const items = [
    {
      name: 'GST Details',
      navigateTo: '/gst'
    },
    {
      name: 'Bank Details',
      navigateTo: '/bank',
    },
    {
      name: 'Project',
      navigateTo: '/project',
    },
    {
      name: 'Day Book',
      navigateTo: '/day-book',
    },
  ];

  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      <a
        className="sidebar-brand d-flex align-items-center justify-content-center"
        href="index.html"
      >
        <div className="sidebar-brand-text mx-1">
          Dashboard
        </div>
      </a>

      <hr className="sidebar-divider my-0" />

      <li className="nav-item active">
        {
          items.map(item => (
            <button
              type="button"
              className="nav-link collapsed"
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer'
              }}
              onClick={() => navigate(item.navigateTo)}
            >
              <i className="fas fa-fw fa-folder"></i>
              <span>{item.name}</span>
            </button>
          ))
        }
      </li>

      <hr className="sidebar-divider d-none d-md-block" />

      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle"></button>
      </div>
    </ul>
  );
};

export default AdminSidebar;
