import { Link } from 'react-router-dom';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import Pagination from 'react-js-pagination';

import { DayBookProps } from '../../utils/customInterfaces';
import { useState } from 'react';
import ViewItemsPerPage from '../ViewItemsPerPage';

interface DayBoolListingProps {
  dayBooksList: DayBookProps[];
  totalItemsCount: number;
  pageIndex: number;
  pageSize: number;
  onChangePageIndex: (pageNumber: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onClickAddDayBook: (dayBook: DayBookProps) => void;
}

const DayBookListing = (props: DayBoolListingProps) => {
  const {
    dayBooksList,
    totalItemsCount,
    pageIndex,
    pageSize,
    onChangePageIndex,
    onChangePageSize,
    onClickAddDayBook,
  } = props;

  const [showDayBookModal, setShowDayBookModal] = useState(false);
  const initiaDayBookState = {
    name: '',
    description: '',
  };
  const [dayBook, setDayBook] = useState(initiaDayBookState);

  const handleShowModal = () => setShowDayBookModal(true);
  const handleCloseModal = () => setShowDayBookModal(false);
  const resetDayBookState = () => setDayBook(initiaDayBookState);
  const handleAddDayBook = (dayBook: DayBookProps) => {
    onClickAddDayBook(dayBook);
    handleCloseModal();
    resetDayBookState();
  };

  return (
    <div>
      <div className="col-md-9">
          <h2 className="h3 mb-4 text-gray-800">Day Books</h2>
      </div>
      <Button className="mb-3" variant="primary" onClick={handleShowModal}>
        Add New
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {dayBooksList.map((dayBook) => (
            <tr key={dayBook.dayBookId}>
              <td>
                <Link to={`${dayBook.dayBookId}`}>{dayBook.dayBookId}</Link>
              </td>
              <td>
                <Link to={`${dayBook.dayBookId}`}>{dayBook.name}</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination 
        activePage = {pageIndex + 1}
        totalItemsCount = {totalItemsCount}
        itemsCountPerPage = {pageSize}
        onChange = {onChangePageIndex}
        itemClass = "page-item"
        linkClass='page-link'
        firstPageText="First"
        lastPageText="Last"
      />
      <ViewItemsPerPage
        selectedPageSize={pageSize}
        onSelectPageSize={onChangePageSize} />
      <Modal show={showDayBookModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Day Book</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder='Enter Name'
                required
                value={dayBook.name}
                onChange={(e) => setDayBook({...dayBook, name: e.target.value})} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={dayBook.description}
                onChange={(e) => setDayBook({...dayBook, description: e.target.value})} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          <Button variant="primary" onClick={() => handleAddDayBook(dayBook)}>Add</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DayBookListing;
